import { publicApi, siteApi } from "@api";
import { deployEnvConfig } from "@configs/deploy-env";
import { ONE_DAY_IN_SECONDS } from "@constants/time";
import { checkClaimable } from "@utils/reward";
import { WalletTxMethod } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useGetRewardsInfo from "./useGetRewardsInfo";
import useServerInfo from "./useServerInfo";
import useUserMode from "./useUserMode";

/**
 * Hook to get the promotion status of Daily Bonus, Cashback, and Referral
 */
const usePromotionStatus = () => {
  const { isGuest } = useUserMode();
  const { data: rewardData, isLoading: isFetchingRewardStatus, refetch: refetchRewardData } = useGetRewardsInfo();
  const { data: referralData, isLoading: isFetchingReferralStatus } = siteApi.useGetReferralInfoQuery(
    {},
    {
      skip: isGuest,
    },
  );
  const { data: referralClaimData, isLoading: isFetchingReferralClaimData } = siteApi.useGetReferralAvailableClaimQuery(
    {},
    {
      skip: isGuest || !referralData?.referralConfig?.visibility,
    },
  );
  const { data: referralRewardStatus, isLoading: isFetchingReferralRewardStatus } =
    siteApi.useGetReferralRewardStatusQuery(undefined, {
      skip: isGuest,
      refetchOnMountOrArgChange: true,
    });

  const { data: referralInfo, isFetching: isFetchingReferralInfo } = publicApi.useGetPublicReferralInfoQuery(
    {
      accountId: deployEnvConfig.country?.accountId,
    },
    {
      skip: !isGuest,
    },
  );

  const isGuestReferralRewardAvailable = useMemo(() => {
    if (isFetchingReferralInfo) return false;
    // @ts-ignore
    return !!referralInfo?.referralBonusTokenSettings?.enabled;
  }, [referralInfo, isFetchingReferralInfo]);

  //Countdown logic for Daily bonus
  const countingDownRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [remainingSeconds, setRemainingSeconds] = useState(ONE_DAY_IN_SECONDS);
  const serverInfo = useServerInfo();

  const stopCountdown = useCallback(() => {
    clearInterval(countingDownRef.current);
  }, []);

  const onSecondTick = useCallback(() => {
    setRemainingSeconds((prev) => {
      if (prev === 1) {
        stopCountdown();
      }
      return prev - 1;
    });
  }, [stopCountdown]);

  const startCountdown = useCallback(() => {
    const now = new Date();
    const delay = 1000 - now.getMilliseconds();
    setTimeout(() => {
      stopCountdown();
      countingDownRef.current = setInterval(onSecondTick, 1000);
    }, delay);
  }, [onSecondTick, stopCountdown]);

  useEffect(() => {
    if (rewardData?.[WalletTxMethod.DailyBonus] && serverInfo?.utcOffset) {
      const remainingSecs = rewardData[WalletTxMethod.DailyBonus].remainingSeconds ?? 0;
      setRemainingSeconds(remainingSecs);
      if (remainingSecs > 0) {
        startCountdown();
      } else {
        stopCountdown();
      }
    }
  }, [rewardData, serverInfo?.utcOffset, startCountdown, stopCountdown]);

  useEffect(() => {
    return () => {
      clearInterval(countingDownRef.current);
    };
  }, []);

  // Check daily login & reward status
  const { isCashbackAvailable, isCashbackClaimable, isDailyBonusAvailable, isDailyBonusClaimable } = useMemo(() => {
    const checkClaimableResult = checkClaimable(rewardData);
    const isCashbackClaimable =
      checkClaimableResult[WalletTxMethod.DailyCashBack] ||
      checkClaimableResult[WalletTxMethod.InstantCashBack] ||
      checkClaimableResult[WalletTxMethod.WeeklyCashBack] ||
      checkClaimableResult[WalletTxMethod.MonthlyCashBack];

    const isCashbackAvailable =
      !!rewardData?.[WalletTxMethod.DailyCashBack] ||
      !!rewardData?.[WalletTxMethod.InstantCashBack] ||
      !!rewardData?.[WalletTxMethod.WeeklyCashBack] ||
      !!rewardData?.[WalletTxMethod.MonthlyCashBack];

    return {
      isCashbackClaimable,
      isCashbackAvailable,
      isDailyBonusAvailable: !!rewardData?.[WalletTxMethod.DailyBonus],
      isDailyBonusClaimable: checkClaimableResult[WalletTxMethod.DailyBonus],
    };
  }, [rewardData]);

  // Check referral reward status
  const { isReferralRewardAvailable, isReferralRewardClaimable } = useMemo(() => {
    return {
      isReferralRewardAvailable: !!referralRewardStatus?.enabled && !!referralRewardStatus?.canParticipate,
      isReferralRewardClaimable: !!referralRewardStatus?.enabled && !!referralRewardStatus?.availableTokenCount,
    };
  }, [referralRewardStatus]);

  // Check referral status
  const { isReferralAvailable, isReferralClaimable } = useMemo(() => {
    return {
      isReferralAvailable: !!referralData?.referralConfig?.visibility,
      isReferralClaimable:
        !!referralData?.referralConfig &&
        referralClaimData?.availableAmount?.gt(0) &&
        referralClaimData?.availableAmount?.gte(referralData?.referralConfig?.minComAmt ?? 0),
    };
  }, [referralData, referralClaimData]);

  // combine status
  const { isAnyAvailable, isAnyClaimable } = useMemo(() => {
    return {
      isAnyAvailable: isCashbackAvailable || isDailyBonusAvailable || isReferralAvailable || isReferralRewardAvailable,
      isAnyClaimable: isCashbackClaimable || isDailyBonusClaimable || isReferralClaimable || isReferralRewardClaimable,
    };
  }, [
    isCashbackAvailable,
    isCashbackClaimable,
    isDailyBonusAvailable,
    isDailyBonusClaimable,
    isReferralAvailable,
    isReferralClaimable,
    isReferralRewardAvailable,
    isReferralRewardClaimable,
  ]);

  const isFetching =
    isFetchingRewardStatus || isFetchingReferralStatus || isFetchingReferralClaimData || isFetchingReferralRewardStatus;

  useEffect(() => {
    if (isGuest) {
      return;
    }

    // The countdown end we will refetch the reward data to see if we can claim the daily bonus
    if (remainingSeconds <= 0 && !isDailyBonusClaimable) {
      // Prevent infinite loop. We will waiting for the next refetch to set the remaining seconds or display claimable
      setRemainingSeconds(ONE_DAY_IN_SECONDS);
      refetchRewardData();
    }
  }, [isDailyBonusClaimable, isGuest, refetchRewardData, remainingSeconds]);

  // Enable all promotions for open mode
  if (isGuest) {
    return {
      isCashbackAvailable: true,
      isCashbackClaimable: true,
      isDailyBonusAvailable: true,
      isDailyBonusClaimable: true,
      isReferralAvailable: true,
      isReferralClaimable: true,
      isReferralRewardAvailable: isGuestReferralRewardAvailable,
      isReferralRewardClaimable: true,
      isAnyAvailable: true,
      isAnyClaimable: true,
      isFetching,
    };
  }

  // Return the status for member mode
  return {
    // cashback
    isCashbackAvailable,
    isCashbackClaimable,
    // daily login
    isDailyBonusAvailable,
    isDailyBonusClaimable,
    remainingSeconds,
    // referral
    isReferralAvailable,
    isReferralClaimable,
    isAnyAvailable,
    isAnyClaimable,
    isFetching,
    isReferralRewardAvailable,
    isReferralRewardClaimable,
  };
};

export default usePromotionStatus;
