import { prepareAccessToken, prepareMarketingData } from "@api/utils";
import { appConfig } from "@configs/application";
import i18n from "@configs/i18n";
import { ACCESS_TOKEN_ENDPOINT, DEFAULT_CONNECTION_ERROR_MESSAGE } from "@constants";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query";
import { RootState } from "@stores";
import auth from "@stores/auth";
import { isTokenExpired } from "@utils/jwt";
import logger from "@utils/logger";
import { transformAccessToken } from "kz-ui-sdk";
import moment from "moment";
import toast from "react-hot-toast";

const BASE_URL = appConfig.server.baseURL;

// Empty response won't trigger error handling
// Use this to prevent error handling if we already handle the error in QueryFn
const EMPTY_RESPONSE = {
  data: {},
};

interface QueueItem {
  resolve: () => void;
  reject: (reason?: any) => void;
}

type QueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>;

let refreshQueue: QueueItem[] = [];
let isRefreshing = false;

function handleQueue(err: Error | null) {
  refreshQueue.forEach((prom) => {
    if (err) {
      prom.reject(err);
    } else {
      prom.resolve();
    }
  });
  isRefreshing = false;
  refreshQueue = [];
}

/**
 * Query with internet connection handling
 * @description Checks if there is an active internet connection before making API requests.
 * If no connection is available, it logs an error and shows a toast notification.
 */
export const withNetworkErrorHandler =
  (queryFn: QueryFn): QueryFn =>
  async (args, api, extraOptions) => {
    try {
      const result = await queryFn(args, api, extraOptions);

      // Check for network errors
      if (result.error?.status === "FETCH_ERROR") {
        // offline
        if (!navigator.onLine) {
          logger._console.log("Network error detected");
          toast.error(i18n.t("no internet connection. please try again."), {
            id: "network-error",
          });

          return EMPTY_RESPONSE;
        }
        // server error
        if (result.error?.error === "TypeError: Failed to fetch") {
          toast.error(i18n.t(DEFAULT_CONNECTION_ERROR_MESSAGE), {
            id: "network-error",
          });

          return EMPTY_RESPONSE;
        }
      }

      return result;
    } catch (error) {
      if (!navigator.onLine) {
        logger._console.log("Network error detected during API call", error);
        toast.error(i18n.t("no internet connection. please try again."), {
          id: "network-error",
        });
        return {
          error: { status: "FETCH_ERROR", error: "No internet connection" } as FetchBaseQueryError,
        };
      }
      throw error;
    }
  };

/**
 * Query with auth header
 * @description Handle auth header and marketing data
 */
export const queryWithAuth = withNetworkErrorHandler(
  fetchBaseQuery({
    baseUrl: BASE_URL,
    timeout: 1000 * 60,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootState;
      prepareAccessToken(headers, api, state);
      prepareMarketingData(headers, api);
    },
  }),
);

/**
 * Query with auth header and refresh token
 * @description Handle auth header, auto refresh token when expired
 */
export const queryWithRefresh: QueryFn = async (args, api, extraOptions) => {
  let shouldRefreshToken = false;
  const state = api.getState() as RootState;
  const accessToken = state.auth.oauth?.access_token;

  if (isRefreshing) {
    logger._console.log("Waiting for refresh token");
    return new Promise<void>((resolve, reject) => {
      refreshQueue.push({ resolve, reject });
    })
      .then(() => {
        logger._console.log("Refresh token success, re-call original api");
        return queryWithRefresh(args, api, extraOptions);
      })
      .catch((error) => {
        logger._console.log("Error while waiting for refresh token", error);
        return Promise.reject(error);
      });
  }

  if (
    // When login with password, we don't need to refresh token
    ((args as FetchArgs).url === ACCESS_TOKEN_ENDPOINT && (args as FetchArgs).body.grant_type === "password") ||
    // When call to public endpoint, we don't need to refresh token
    !accessToken
  ) {
    // Return original api call
    return queryWithAuth(args, api, extraOptions);
  }

  let result;
  // Check if token is expired
  if (isTokenExpired(accessToken)) {
    logger._console.log("Access token expired, skip request");
    shouldRefreshToken = true;
  } else {
    // If token is not expired, call original api
    result = await queryWithAuth(args, api, extraOptions);
  }

  // If token expired or api call return 401, try to refresh token
  if (shouldRefreshToken || (result && result.error && result.error.status === 401)) {
    // Prevent multiple refresh token call
    isRefreshing = true;
    logger._console.log("Start refresh token");
    const state = api.getState() as RootState;
    let tokenReloadSuccess = false;
    api.dispatch(
      auth.slice.actions.progressRefreshAccessToken({
        isRefreshingToken: true,
      }),
    );
    // Check if refresh token is available and not expired
    if (state.auth.oauth?.refresh_token && moment.unix(state.auth.oauth?.refresh_expires_at ?? 0).isAfter(moment())) {
      const refreshToken = state.auth.oauth.refresh_token;
      const refreshResult = await queryWithAuth(
        {
          method: "POST",
          url: ACCESS_TOKEN_ENDPOINT,
          body: {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          },
        },
        api,
        extraOptions,
      );
      if (refreshResult.data) {
        logger._console.log("Refresh token api call success, re-call original api");
        const authToken = transformAccessToken(refreshResult.data);
        api.dispatch(auth.slice.actions.updateAccessToken(authToken));
        handleQueue(null);
        // Refresh token success, re-call original api
        result = await queryWithAuth(args, api, extraOptions);
        tokenReloadSuccess = true;
      }
    }
    // If refresh token failed, logout user
    if (!tokenReloadSuccess) {
      if (state.auth.oauth?.refresh_token) {
        logger._console.log("Refresh token expired");
        toast.error(i18n.t("Your session has expired, please log in again!"));
      }
      handleQueue(new Error("Failed to refresh token"));
      api.dispatch(auth.slice.actions.logout());
    }

    // Check if user is suspended
    if (result && result.error && result.error.status === 403) {
      logger._console.log("user is suspended");
      const text = i18n.t("Please contact customer service!");
      api.dispatch(auth.slice.actions.logout());
      handleQueue(new Error(text));
      toast.error(i18n.t(text));
    }

    api.dispatch(
      auth.slice.actions.progressRefreshAccessToken({
        isRefreshingToken: false,
      }),
    );
  }

  return (
    result ?? {
      error: {
        status: 401,
        data: "Refresh token expired",
      },
    }
  );
};
